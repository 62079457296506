<script setup lang="ts">
import { inject } from 'vue';
import type { PageNodeModel } from '../../../page-management-tool/PageNode.js';
import type { BucketAndSelectorConfig, BucketTitles } from '../../../page-management-tool/pmt-interfaces.js';
import BucketComponents from '../../individual-entry-component/bucket-components.vue';
import LabelTitle from '../../individual-entry-component/field-label.vue';
import EditData from '../../individual-entry-component/edit-modal.vue';

defineProps<{
  bucketConfig: BucketAndSelectorConfig;
  result: PageNodeModel;
  index2: number;
}>();
function buildTitleClass(title: string) {
  var spaceTest = /\s$/.test(title);
  if (spaceTest) {
    return title.toLowerCase();
  } else {
    return title.replace(/\s+/g, '-').toLowerCase();
  }
}
function IsFieldEditable(bucket: BucketTitles ): boolean{ 

  if(bucket.canEdit && bucket.ComponentType == "string")
    return true;

  return false;
}
const activeTitle = inject("activeTitle") as string;
</script>
<template>
  <div v-if="activeTitle === bucketConfig.selector">
    <div class="content-bucket flex active" :class="buildTitleClass(activeTitle)">
      <!-- Build the Content based on bucket type -->
      <div class="content-info">
        <template v-for="field in bucketConfig.bucketTitles" :key="field.title">
          <div class="content-row flex" :class="[buildTitleClass(field.title), IsFieldEditable(field) ? 'editable': '']">
            <LabelTitle v-if="field.useTitle" :label-title="field.title" :tool-tip="field.toolTip" />
            <EditData v-if="IsFieldEditable(field)" :data-to-edit="result" :field-name="field.title" :getter="field.getValue"></EditData>
            <BucketComponents :data="field.getValue(result)" :component-type="field.ComponentType" :label="field.title" />
          </div>
        </template>
      </div>
    </div>
</div></template>