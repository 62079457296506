import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "content-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.activeTitle === $props.bucketConfig.selector)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["content-bucket flex active", $setup.buildTitleClass($setup.activeTitle)])
        }, [
          _createCommentVNode(" Build the Content based on bucket type "),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bucketConfig.bucketTitles, (field) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.title,
                class: _normalizeClass(["content-row flex", [$setup.buildTitleClass(field.title), $setup.IsFieldEditable(field) ? 'editable': '']])
              }, [
                (field.useTitle)
                  ? (_openBlock(), _createBlock($setup["LabelTitle"], {
                      key: 0,
                      "label-title": field.title,
                      "tool-tip": field.toolTip
                    }, null, 8 /* PROPS */, ["label-title", "tool-tip"]))
                  : _createCommentVNode("v-if", true),
                ($setup.IsFieldEditable(field))
                  ? (_openBlock(), _createBlock($setup["EditData"], {
                      key: 1,
                      "data-to-edit": $props.result,
                      "field-name": field.title,
                      getter: field.getValue
                    }, null, 8 /* PROPS */, ["data-to-edit", "field-name", "getter"]))
                  : _createCommentVNode("v-if", true),
                _createVNode($setup["BucketComponents"], {
                  data: field.getValue($props.result),
                  "component-type": field.ComponentType,
                  label: field.title
                }, null, 8 /* PROPS */, ["data", "component-type", "label"])
              ], 2 /* CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ], 2 /* CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}