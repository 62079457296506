import type { PageNodeModel as P } from "../PageNode.js";
import type { PropConfig } from "../pmt-interfaces.js";

const tableHeaderValue: PropConfig<P>[] = [
    {
        title: "ISO",
        class: "lang-select",
        arrow: false,
        type: "dropdown",
        getValue(obj: P) { return obj.getAvailableLanguages; },
        fieldForSort: "",
    },
    { 
        title: "URL", 
        class: "page-url", 
        arrow: true, 
        type: "string", 
        getValue(obj: P) { return obj.LangSpecificPageData.Url;},
        fieldForSort: "getUrl"
    },
    { 
        title: "Status", 
        class: "page-status", 
        arrow: true, 
        type: "status",
        getValue(obj: P) { return obj.LangSpecificPageData?.Status?.toLowerCase();},
        fieldForSort: "LangSpecificPageData.Status"
    },
    { 
        title: "Owner Count", 
        class: "page-owner", 
        arrow: true, 
        type: "string",
        getValue(obj: P) { 
            //return obj.LangSpecificPageData.PageOwners?.map(x => x.FullName).join(", ");
            return `${obj.LangSpecificPageData.PageOwners?.length}`;
        },
        fieldForSort: "LangSpecificPageData.PageOwners",
        
    },
    { 
        title: "Review", 
        class: "review-date", 
        arrow: true, 
        type: "date",
        getValue(obj: P) { return obj.LangSpecificPageData.ReviewDate;},
        fieldForSort: "LangSpecificPageData.ReviewDate"
        
    },
    { 
        title: "Action", 
        class: "action", 
        arrow: true, 
        type: "action",
        getValue(obj: P) { return obj.LangSpecificPageData.ReviewAction;},
        fieldForSort: "LangSpecificPageData.ReviewAction"
    }
];
export default tableHeaderValue;