<script setup lang="ts">
  import { defineAsyncComponent, type Component } from 'vue';
  import { FormatMyDate } from '../../composables.js';
  import { exhaustiveGuard } from '@mcwd/typescript-type-guards';
  import type { AdditionalUrlsData, PageNodeModelInterface } from '../../page-management-tool/pmt-interfaces.js';

  interface BucketComponent{
    data: string | string[] | boolean | [PageNodeModelInterface, AdditionalUrlsData[]];
    label: string;
    componentType: string;
  }
  const props = defineProps<BucketComponent>();
  const dynamicComponent = defineAsyncComponent<Component<BucketComponent>>(() => {
    if(props.componentType === "checkOrX") {
      return import("./check-or-x-text.vue");
    }
    else if(props.componentType === "redirect-console"){
      return import("../redirect-console/redirect-console.vue");
    }
    else{
      return exhaustiveGuard(props.componentType as never);
    }
  });
</script>
<template>
  <div v-if="componentType == 'string'" class="content-data">
    {{ data }}
  </div>
  <div v-else-if="componentType == 'Date' && typeof data == 'string'" class="content-data">
    {{ FormatMyDate(data, "d LLL yyyy t" ) }}
  </div>
  <div v-else-if="componentType == 'Array'" class="content-data">
    <div v-for="entry in data" :key="entry">
      {{ entry }}
    </div>
  </div>
  <div v-else-if="componentType == 'Button' && typeof data == 'string'" class="content-data">
    <a :href="data" class="btn btn-teal" role="button" target="_blank">{{ label }}</a>
  </div>
  <div v-else-if="componentType == 'Image'" class="content-data">
  </div>
  <div v-else>
    <dynamicComponent v-bind="props" />
  </div>
</template>