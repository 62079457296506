<script setup lang="ts">
import type { CustomFilterEmitData } from './pmt-interfaces.js';
import filtersComponent from '../shared_components/filter-componets/filters-component.vue';
import tableComponent from '../shared_components/table-components/table-component.vue';
//import PostUpdateMessage from "../shared_components/post-update-message/data-refresh.vue";
import { PageNodeModel } from './PageNode.js';
import { bucketStructure, BucketTitles } from './tool-config/bucket-selector-config.js';
import tableHeaderValue from './tool-config/table-header-value-config.js';
import { ref, provide, computed, onMounted, watch } from 'vue';
import { CsvDataServices } from '../csvBuilder.js';
import { QuickFilterOptions, SearchFilterDropDown } from './tool-config/filter-config.js';
import { usePageNodeStore } from './page-node-store.js';
import { storeToRefs } from 'pinia';

interface PageManagementToolProps{
  PageUrlUpdate: string,
  PageAdditionalUrlUpdate: string,
  PageDeleteAndRedirectUpdate: string,
}
const props = defineProps<PageManagementToolProps>();

const PageNodeStore = usePageNodeStore();
const { allPageNodes } = storeToRefs(usePageNodeStore());
let errorHappened = ref(false);
let filterData = ref<CustomFilterEmitData | null>(null);
let CustomFieldData = computed(()=>{
  return PageNodeStore.DynamicFilterValues;
});
const csvReportRequiredFields = BucketTitles.concat(tableHeaderValue.map(x => x.title).filter(x => x != 'URL'));
const isSaving = computed(()=>{
  return PageNodeStore.IsValueBeingSaved;
});
const isLoading = computed(()=>{
  return PageNodeStore.IsLoading;
});
const needsDataRefresh = computed(()=>{
  return PageNodeStore.NeedsRefresh;
})
watch(needsDataRefresh, (newValue)=>{
  if(newValue){
    getRefresh();
  }
})

//handle emit events
function handleFilterEmit(data: CustomFilterEmitData | null) {
  filterData.value = data;
  PageNodeStore.FilterAllPages(data);
}
function handleSortEmit(data) {
  PageNodeStore.SortAllPages(data);
}
function handleCsv(requiredFields: string[]) {
  const filename = "page-management-report";
  CsvDataServices.downloadCSV<PageNodeModel>(bucketStructure, tableHeaderValue, allPageNodes.value, requiredFields, filename);
}
async function getRefresh(){
  PageNodeStore.DataNeedsRefresh = false;
  await PageNodeStore.fetchAllPageNodeData();
}
onMounted(async ()=>{
  await PageNodeStore.fetchAllPageNodeData();
  PageNodeStore.SetBackEndModel(props);
})
//provide configurations to child components
provide("bucket-config", bucketStructure);
provide("table-config", tableHeaderValue);
provide("review-action-config", QuickFilterOptions.FilterOptions);
provide("csv-headers", csvReportRequiredFields);
</script>
<template>
  <div class="main-content">
    <section class="masthead">
      <h1>Page Management Tool</h1>
    </section>
    <div id="loading-overlay" v-show="isSaving">
        <strong>Saving Changes <i class="fa fa-spinner fa-pulse"></i></strong>
    </div>
    <div v-if="(isLoading && !errorHappened)" class="spinner-parent">
      <div class="spinner-border" role="status"></div>
      <span class="spinner-text">Loading Tool...</span>
    </div>
    <template v-else>
      <filtersComponent :custom-filter-options="CustomFieldData" @custom-filter="handleFilterEmit"
        :quick-filter-options="QuickFilterOptions" :search-able-fields="SearchFilterDropDown"/>
    </template>
    <tableComponent :data-to-display="allPageNodes"
        @sort-results="handleSortEmit" @download-csv="handleCsv" v-if="!isLoading && allPageNodes.length > 0" />
  </div>
</template>
