import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["tabWrapper"], null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bucketConfig, (el, index) => {
        return (_openBlock(), _createBlock($setup["tab"], {
          key: el.selector,
          "bucket-config": el,
          index2: index,
          result: $props.result
        }, null, 8 /* PROPS */, ["bucket-config", "index2", "result"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}