import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "content-wrpr flex" }
const _hoisted_2 = { class: "left-side flex" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "right-side" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["full-content flex", {active: true}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabTitles, (el, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["content-selector flex", [index === $setup.activeIndex ? 'active' : '']]),
            onClick: $event => ($setup.activateBucket(el, index))
          }, [
            _createElementVNode("p", null, _toDisplayString(el), 1 /* TEXT */)
          ], 10 /* CLASS, PROPS */, _hoisted_3))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}