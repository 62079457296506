import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-data"
}
const _hoisted_2 = {
  key: 1,
  class: "content-data"
}
const _hoisted_3 = {
  key: 2,
  class: "content-data"
}
const _hoisted_4 = {
  key: 3,
  class: "content-data"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 4,
  class: "content-data"
}
const _hoisted_7 = { key: 5 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.componentType == 'string')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.data), 1 /* TEXT */))
    : ($props.componentType == 'Date' && typeof $props.data == 'string')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.FormatMyDate($props.data, "d LLL yyyy t" )), 1 /* TEXT */))
      : ($props.componentType == 'Array')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (entry) => {
              return (_openBlock(), _createElementBlock("div", { key: entry }, _toDisplayString(entry), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : ($props.componentType == 'Button' && typeof $props.data == 'string')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                href: $props.data,
                class: "btn btn-teal",
                role: "button",
                target: "_blank"
              }, _toDisplayString($props.label), 9 /* TEXT, PROPS */, _hoisted_5)
            ]))
          : ($props.componentType == 'Image')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode($setup["dynamicComponent"], _normalizeProps(_guardReactiveProps($setup.props)), null, 16 /* FULL_PROPS */)
              ]))
}