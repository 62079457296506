<script setup lang="ts">
  import { inject } from 'vue';
  import type { PageNodeModel } from '../../../page-management-tool/PageNode.js';
  import type { BucketAndSelectorConfig} from '../../../page-management-tool/pmt-interfaces.js';
  import tab from './tab-entry.vue';
  import tabWrapper from './tab-wrapper.vue';

  defineProps<{
    result: PageNodeModel,
  }>();
  //inject configurations
  const bucketConfig = inject("bucket-config") as  BucketAndSelectorConfig[];
</script>
<template>
  <tabWrapper>
    <tab v-for="(el, index) in bucketConfig" :key="el.selector" :bucket-config="el" :index2="index" :result="result" />
  </tabWrapper>
</template>