<script setup lang="ts">
  import { ref, useSlots, type RendererElement, type RendererNode, type VNode } from 'vue';
  import { provide } from 'vue';

  const slots = useSlots();
  const defSlot = (slots.default ? slots.default() : null) as  VNode<RendererNode, RendererElement, {[key: string]: any;}>[];
  function GetSlotKeys(){
    if(defSlot && defSlot[0] && defSlot[0].children){
      return (defSlot[0].children as any[]).map(tab => (tab.key as string));
    }
    return [""];
  }
  const tabTitles =  ref(GetSlotKeys());
  const activeTitle = ref(tabTitles.value[0]);
  const activeIndex = ref(0);
  function activateBucket(el: string, i: number){
    activeTitle.value = el;
    activeIndex.value = i;
  }
  provide("activeTitle", activeTitle);
</script>
<template>
  <div class="full-content flex" :class="{active: true}">
    <div class="content-wrpr flex">
      <div class="left-side flex">
        <template v-for="(el, index) in tabTitles" :key="index">
          <div class="content-selector flex" :class="[index === activeIndex ? 'active' : '']" @click="activateBucket(el, index)">
            <p>{{ el }}</p>
          </div>
        </template>
      </div>
      <div class="right-side">
        <slot></slot>
      </div>
    </div>
  </div>
</template>