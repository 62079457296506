import type { PageNodeModel } from "../PageNode.js";
import type { BucketAndSelectorConfig } from "../pmt-interfaces.js";

export const bucketStructure: BucketAndSelectorConfig[] = [
    {
       selector:"Page Details",
       bucket:"page-details",
       bucketTitles:[
          {
             title:"Page URL",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.Url;
             }, 
             ComponentType: "string",
             toolTip:"",
             canEdit: true,
          },
          {
             title:"Publish Date",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.LastPublished;
             }, 
             ComponentType: "Date",
             toolTip: "Last time this page was published"
          },
          {
             title:"Revision Date",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.LastModifiedPageContent;
             }, 
             ComponentType: "Date",
             toolTip: "Last time this page was updated"
          },
          {
            title:"Metadata Revision Date",
            useTitle:true,
            getValue(obj: PageNodeModel){
              return obj.LangSpecificPageData.LastModifiedPageMetaData;
            }, 
            ComponentType: "Date",
            toolTip: "Last time this page's title and properties was revised"
         },
          {
             title:"Review Date",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.ReviewDate;
             }, 
             ComponentType: "Date",
             toolTip:"This page's next review date"
          },
          {
             title:"Page Sync",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.getLocalizationStrategy;
             }, 
             ComponentType: "string",
             toolTip: "This page's localization status"
          },
          {
            title: "Page Owners",
            useTitle: true,
            getValue(obj:PageNodeModel){
               return obj.LangSpecificPageData.PageOwners?.map(x => x.FullName).join(", ");
            },
            ComponentType: "string",
            toolTip: "",
          },
          {
             title:"Edit Page",
             useTitle:false,
             getValue(obj: PageNodeModel){
               return `${obj.getUrl}action/edit/${obj.langSelected}`;             
            }, 
            ComponentType: "Button",
            toolTip: ""
          },
          {
            title:"Preview Page",
            useTitle:false,
            getValue(obj: PageNodeModel){
              return `${obj.getUrl}action/preview/${obj.langSelected}`;
            }, 
            ComponentType: "Button",
            toolTip: ""
         }
       ]
    },
    {
       selector:"Title and Properties",
       bucket:"properties",
       bucketTitles:[
          {
             title:"Page Title",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.Name;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Title for SEO",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.SearchEngineTitle;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Meta Description",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.Description;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Indexing",
             useTitle: true,
             getValue(obj: PageNodeModel){
               return obj.getExternalIndexable;
             }, 
             ComponentType: "checkOrX",
             toolTip: ""
          },
          {
             title:"Caching",
             useTitle:true,
             getValue(obj: PageNodeModel){
               const value = obj.getCachingRule;
               if(value === null || value === "") return "As set for the whole site";
               return value;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Canonical URL",
             useTitle:true,
             getValue(obj: PageNodeModel){
               const CanonicalUrl = obj.getCononicalUrl;
               if(CanonicalUrl !== null && CanonicalUrl) return "Enabled";
               else if(CanonicalUrl !== null )
               return "Disabled";
               return "As set for the whole site";
             }, 
             ComponentType: "string",
             toolTip: ""
          }
       ]
    },
    {
       selector:"Page Hierarchy",
       bucket:"page-hierarchy",
       bucketTitles:[
          {
             title:"Template",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.getTemplate;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Parent Page",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.ParentPage;
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Child Pages",
             useTitle:true,
             getValue(obj: PageNodeModel){              
               return obj.childs;
             }, 
             ComponentType: "Array",
             toolTip:""
           },
       ]
    },
    {
      selector: "Redirects",
      bucket:"redirects",
      bucketTitles:[
         {
            title: "Redirects to this Page",
            useTitle: true,
            getValue(obj: PageNodeModel){
               return obj.LangSpecificUrls;
             }, 
             ComponentType: "redirect-console",
             toolTip:""
         }
      ]
    },
    {
       selector:"Taxonomies",
       bucket:"taxonomies",
       bucketTitles:[
          {
             title:"Solutions",
             useTitle:true,
             getValue(){
               return "";
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Industries",
             useTitle:true,
             getValue(){
               return "";
             }, 
             ComponentType: "string",
             toolTip:""
          },
          {
             title:"Buyer Journey",
             useTitle:true,
             getValue(){
               return "";
             }, 
             ComponentType: "string",
             toolTip: ""
          },
          {
             title:"Topics",
             useTitle:true,
             getValue(){
               return "";
             }, 
             ComponentType: "string",
             toolTip:""
          }
       ]
    },
    {
       selector:"Notes",
       bucket:"notes",
       bucketTitles:[
          {
             title:"Notes",
             useTitle:true,
             getValue(obj: PageNodeModel){
               return obj.LangSpecificPageData.Notes;
             }, 
             ComponentType: "string", 
             toolTip: ""
          }
       ]
    }
 ];
 export const BucketTitles = bucketStructure.map(x => x.bucketTitles.map(y => y.title)).flat();
