<script setup lang="ts">
import { computed, ref } from 'vue';
import type { PageNodeModel } from '../../page-management-tool/PageNode.js';
import DataBucket from './expand-area/data-bucket.vue';
import { FormatMyDate } from '../../composables.js';
import DropDown from '../individual-entry-component/drop-down.vue';
import type { PropConfig } from '../../page-management-tool/pmt-interfaces.js';
import { inject } from 'vue';

const props = defineProps<{
  dataToDisplay: PageNodeModel,
  headerToFieldMap: PropConfig[],
  displayBucket: boolean,
  index: number
}>();
let activateBucket = ref(props.displayBucket);
const emit = defineEmits(["newLangSelected"]);
const languageSelected = computed({
  get() {
    return props.dataToDisplay.langSelected;
  },
  set(newValue) {
    emit("newLangSelected", newValue);
  }
});
function FormatString(date: string | string[]) {
  return FormatMyDate(date as string, 'd LLL yyyy');
}
function toggleBucket(event:Event): void {
  activateBucket.value = !activateBucket.value;
}
const reviewDropDowns = inject("review-action-config") as string[];
</script>
<template>
  <div :id="'result-' + index" :key="dataToDisplay.getNodeId" class="result" @click="toggleBucket">
    <div class="short-content flex">
      <template v-for="field in headerToFieldMap" :key="field">
        <select v-if="field.type === 'dropdown'" v-model="languageSelected" :class="field.class" @click.stop="">
          <option v-for="lang in field.getValue(dataToDisplay)" :key="lang" class="dropdown-arrow">
            {{ lang }}
          </option>
        </select>
        <div v-if="field.type === 'string'" :class="field.class">
          {{ field.getValue(dataToDisplay) }}
        </div>
        <div v-if="field.type === 'status'" :class="field.class">
          <!-- Graphic will show here -->
          <div class="status-graphic" :class="field.getValue(dataToDisplay)">
            {{ field.getValue(dataToDisplay) }}
          </div>
        </div>
        <div v-if="field.type === 'date'" :class="field.class">
          {{ FormatString(field.getValue(dataToDisplay)) }}
        </div>
        <DropDown v-if="field.type === 'action'" :drop-down-values="reviewDropDowns" @click.stop=""/>
      </template>
      <div class="expand flex js-expand" :class="{ expanded: activateBucket }">
        <svg class="plus" height="12" viewbox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5 5C11.625 5 11.75 5.0625 11.8438 5.15625C11.9375 5.25 12 5.375 12 5.5V6.5C12 6.65625 11.9375 6.78125 11.8438 6.875C11.75 6.96875 11.625 7 11.5 7H7V11.5C7 11.6562 6.9375 11.7812 6.84375 11.875C6.75 11.9688 6.625 12 6.5 12H5.5C5.34375 12 5.21875 11.9688 5.125 11.875C5.03125 11.7812 5 11.6562 5 11.5V7H0.5C0.34375 7 0.21875 6.96875 0.125 6.875C0.03125 6.78125 0 6.65625 0 6.5V5.5C0 5.375 0.03125 5.25 0.125 5.15625C0.21875 5.0625 0.34375 5 0.5 5H5V0.5C5 0.375 5.03125 0.25 5.125 0.15625C5.21875 0.0625 5.34375 0 5.5 0H6.5C6.625 0 6.75 0.0625 6.84375 0.15625C6.9375 0.25 7 0.375 7 0.5V5H11.5Z"
            fill="#C53494"></path>
        </svg>
        <svg class="minus" height="2" viewbox="0 0 12 2" width="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5 0C11.625 0 11.75 0.0625 11.8438 0.15625C11.9375 0.25 12 0.375 12 0.5V1.5C12 1.65625 11.9375 1.78125 11.8438 1.875C11.75 1.96875 11.625 2 11.5 2H0.5C0.34375 2 0.21875 1.96875 0.125 1.875C0.03125 1.78125 0 1.65625 0 1.5V0.5C0 0.375 0.03125 0.25 0.125 0.15625C0.21875 0.0625 0.34375 0 0.5 0H11.5Z"
            fill="#C53494"></path>
        </svg>
      </div>
    </div>
  <DataBucket v-if="activateBucket" :result="dataToDisplay" @click.stop=""/>
</div></template>