import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content-title" }
const _hoisted_2 = {
  key: 0,
  class: "tooltiptext"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString($props.labelTitle) + " ", 1 /* TEXT */),
    ($props.toolTip || $props.toolTip != '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.toolTip), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}